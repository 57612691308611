import { Field, Form, Formik } from "formik";
import { navigate } from "gatsby";
import React from "react";
import { useDispatch } from "react-redux";
import { Input } from "../../../components/InputFields";
import { SidebarLayout } from "../../../components/Layout";
import SettingsSidebar from "../../../components/SettingsSidebar";
import { addMessage } from "../../../state/reducers/appReducer";
import { logoutAction } from "../../../state/reducers/authReducer";
import { fetchBaseOptions } from "../../../utils/fetch";
import { PasswordSchema } from "../../../utils/validators";

const DeleteAccountPage = () => {
  const dispatch = useDispatch();
  return (
    <SidebarLayout sidebar={<SettingsSidebar />} seo={{ title: "Delete account" }}>
      <Formik
        initialValues={{ password: "" }}
        validationSchema={PasswordSchema}
        onSubmit={(values, { setSubmitting }) => {
          fetch("/api/user/delete", {
            ...fetchBaseOptions,
            method: "DELETE",
            body: JSON.stringify(values),
          })
            .then(async res => {
              const j: IAPIResponse = await res.json();
              dispatch(addMessage(j));
              if (res.ok) {
                dispatch(logoutAction());
                navigate("/");
              }
            })
            .catch(err => console.error(err))
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form className="space-y-2">
            <Field
              required={true}
              type="password"
              name="password"
              label="Password"
              component={Input}
            />
            <button
              className="w-full btn btn-delete"
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              Delete
            </button>
          </Form>
        )}
      </Formik>
    </SidebarLayout>
  );
};

export default DeleteAccountPage;
